import React, { useEffect } from 'react';
import { NextComponentType } from 'next';
import Head from 'next/head';
import { defineMessages, useIntl } from 'react-intl';
import { useRouter } from 'next/router';
import { config as fontAwesomeConfig } from '@fortawesome/fontawesome-svg-core';
import { trackPageView, useStoreMarketingValues } from '../components/googleAnalytics';

import { nextAsset } from '../components/nextAsset';
import { config } from '../config/config';
import { ConfigEnvironment } from '../config/ConfigType';

fontAwesomeConfig.autoAddCss = false;

declare global {
  interface Window {
    dataLayer: any | undefined;
    fbq: any | undefined;
    gtag: any | undefined;
    mapkit: any;
    MusicKit: any;
    Paddle: any;
    plausible: any;
    onBlogCTAClicked: () => void;
    trackConversion: any;
  }
}

const { pageDescription, pageName } = defineMessages({
  pageDescription: {
    id: 'web.page.description',
    defaultMessage: 'Loved by 35k+ managers & 1m+ employees at Netflix, Facebook, Spotify and others!'
  },
  pageName: {
    id: 'web.main_title',
    defaultMessage: 'Wildly fun team building experiences hosted by you'
  }
});

const BaseComponent: React.FC<{
  Component: NextComponentType;
  pageProps: any;
}> = ({ Component, pageProps }) => {
  const intl = useIntl();
  const router = useRouter();

  useStoreMarketingValues();

  useEffect(() => {
    trackPageView();
  }, [router.asPath]);

  const title = intl.formatMessage(pageName);
  const description = intl.formatMessage(pageDescription);
  const promoImg = nextAsset(`logos/promo-${router.locale}@2x.png`, false, 'w=1200');

  /* eslint-disable react/no-danger */
  return (
    <>
      <Head>
        <meta
          name="apple-mobile-web-app-title"
          content={intl.formatMessage({
            id: 'web.app.name',
            defaultMessage: 'Quizado'
          })}
        />
        <meta name="mobile-web-app-capable" content="yes" />

        <meta itemProp="name" content={title} />
        <meta itemProp="description" content={description} />
        <meta name="description" content={description} />
        <meta itemProp="image" content={promoImg} />
        <meta property="twitter:site" content="@QuizadoApp" />
        <meta property="twitter:title" content={title} />
        <meta property="twitter:description" content="" />
        <meta property="twitter:image" content={promoImg} />
        <meta property="twitter:image:width" content="2400" />
        <meta property="twitter:image:height" content="1200" />
        <meta property="twitter:card" content="summary_large_image" />

        <meta property="og:image" content={promoImg} />
        <meta property="og:type" content="website" />
        <meta
          property="og:url"
          content={`https://${intl.formatMessage({
            id: 'web.domain',
            defaultMessage: 'quizado.com'
          })}`}
        />
        {/* <meta property="fb:app_id" content="xxxxxxxxx" /> */}
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />

        <script defer src={`https://www.googletagmanager.com/gtag/js?id=${config.gTagID}`} />
        <script
          dangerouslySetInnerHTML={{
            __html:
              'window.dataLayer = window.dataLayer || [];\n' +
              'function gtag(){dataLayer.push(arguments);}\n' +
              "gtag('js', new Date());"
          }}
        />
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify({
              '@context': 'https://schema.org',
              '@type': 'SoftwareApplication',
              operatingSystem: 'iOS,Android,Windows,Linux,macOS',
              name: intl.formatMessage({
                id: 'web.app.name',
                defaultMessage: 'Quizado'
              }),
              image: `https://${intl.formatMessage({
                id: 'web.domain',
                defaultMessage: 'quizado.com'
              })}${nextAsset(`logos/logo-square-rounded@1024px.png`, true)}`,
              url: `https://${intl.formatMessage({
                id: 'web.domain',
                defaultMessage: 'quizado.com'
              })}/`,
              applicationCategory: 'https://schema.org/UtilitiesApplication',
              downloadUrl: `https://${intl.formatMessage({
                id: 'web.domain',
                defaultMessage: 'quizado.com'
              })}/en/download`,
              aggregateRating: {
                '@type': 'AggregateRating',
                ratingValue: '4.8',
                ratingCount: '14689'
              },
              offers: {
                '@type': 'Offer',
                price: '0'
              }
            })
          }}
        />

        {config.env === ConfigEnvironment.PRODUCTION ? (
          <script
            dangerouslySetInnerHTML={{
              __html:
                '!function(f,b,e,v,n,t,s)\n' +
                '{if(f.fbq)return;n=f.fbq=function(){n.callMethod?\n' +
                'n.callMethod.apply(n,arguments):n.queue.push(arguments)};\n' +
                "if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';\n" +
                'n.queue=[];t=b.createElement(e);t.defer=!0;\n' +
                't.src=v;s=b.getElementsByTagName(e)[0];\n' +
                "s.parentNode.insertBefore(t,s)}(window, document,'script',\n" +
                "'https://connect.facebook.net/en_US/fbevents.js');\n" +
                "fbq('init', '162197822075583');\n" +
                "fbq('track', 'PageView');"
            }}
          />
        ) : null}
      </Head>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <Component {...pageProps} />
    </>
  );
  /* eslint-enable react/no-danger */
};

export const Base = React.memo(BaseComponent);
