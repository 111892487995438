import React, { useCallback } from 'react';
import { scroller } from 'react-scroll';
import { useRouter } from 'next/router';

export function getParameterByName(name: string, url: string): string | null {
  const parsedName = name.replace(/[[\]]/g, '\\$&');

  const regex = new RegExp(`[?&]${parsedName}(=([^&#]*)|&|#|$)`);
  const results = regex.exec(url);
  if (!results) {
    return null;
  }
  if (!results[2]) {
    return '';
  }
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

export const capitalizeFirstLetter = (string: string): string => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export function notEmpty<TValue>(value: TValue | null | undefined): value is TValue {
  return value !== null && value !== undefined;
}

export const useScrollToHashPosition = (
  hash: string,
  href: string,
  as?: string,
  duration = 600
): [{ href: string; as: string }, (event: React.MouseEvent<HTMLButtonElement>) => void] => {
  const router = useRouter();
  const hashedAs = `${router.asPath}#${hash}`;
  const shallow = hashedAs !== router.asPath;
  return [
    { href: router.pathname, as: hashedAs },
    useCallback(
      (event: React.MouseEvent<HTMLButtonElement>) => {
        if (shallow) {
          scroller.scrollTo(hash, {
            duration,
            delay: 0,
            smooth: 'easeInOutQuart',
            offset: -60
          });
          router.push(`${router.pathname}#${hash}`, hashedAs, { shallow: true }).catch((e) => console.error(e));
        } else {
          router
            .push(`${router.pathname}#${hash}`, hashedAs, { shallow: false })
            .then(() => {
              scroller.scrollTo(hash, {
                duration,
                delay: 0,
                smooth: 'easeInOutQuart',
                offset: -60
              });
            })
            .catch((e) => console.error(e));
        }
        event.preventDefault();
      },
      [duration, hash, hashedAs, router, shallow]
    )
  ];
};
